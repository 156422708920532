
.shirt-card-box:hover .overlay {
  opacity: 1;
}
.shirt-card-box:hover .shirt-image {
  transition:200ms;
  opacity: 0;
}

.success-bid{
display: flex;
justify-content: center;
}
.success-bid-text{
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}