.ant-carousel .slick-dots-bottom {
    bottom: 0;
    z-index: 0;
}

.ant-carousel .slick-dots li.slick-active button {
    background:  #00BCD4;
    opacity: 1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

.ant-carousel .slick-dots li button {
    width: 100%;
    width: 16px;
    height: 16px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border-radius: 50% !important;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 1;
    transition: all 0.5s;
}